import logo from "./logo.png"

const clientData = {
    client_entity: 51,
    attorney_firm: 'Jasper Legal',
    attorney_name: 'Jessica Jasper',
    attorney_number: '601 988-7605',
    attorney_email: 'jessica@jasperlegal.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_warren_%26_migliaccio+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#00258d',
    siteLink: 'http://jasperlegal.com/',
    logo
}

export default clientData